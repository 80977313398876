<script>
import api from '@/command/api'
import DetailFormGroup from '../../../components/DetailFormGroup'
import { getAction, putAction } from '@/command/netTool'
import CardIconLabelLocal from '../../../components/CardIconLabelLocal'
import Naves from '@/views/com/Naves'
import {
  broadbandSituation,
  extraBed,
  smoking,
  wirelessWIFI,
  bedType as bedTypeArr,
  theWindow,
  hotelBreakFast,
} from '@/utils/textFile'
import { hotelDaysAfterList, timeAfterList, ticketRefundType1, ticketRefundRatioList } from '@/utils/textFile'
import { message } from 'ant-design-vue'

export default {
  name: 'roomManageDetail',
  data() {
    return {
      ...api.command.getState(),
      comboName: [],
      detail: {
        comboFlag: '1',
        roomArea: null,
        floorDistribution: null,
        maximumOccupancy: null,
        bedTypeArea: null,
        roomNum: null,
        refundExplain: '1',
        ticketRefundList: [
          {
            validDay: '0',
            validDate: '6',
            refundType: '0',
            refundExplain: 0,
          },
        ],
      },
      bed_type_arr: [],
      window_arr: [],
      labelGroup: [],
      hotelId: '',
      activeKey: 0,
    }
  },
  mounted() {
    const { id, hotelId } = this.$route.query
    this.hotelId = hotelId
    if (id) {
      this.getDetail(id)
    }
    this.$forceUpdate()
    api.command.getLabelCodeGroup.call(this, {
      url: '/api/base/farmLabelData/getByCodeSelf',
      params: {
        id: id ?? '',
        codes: [
          {
            key: 'farmhouse_food_safety',
          },
          {
            key: 'farmhouse_bathroom',
          },
          {
            key: 'farmhouse_room',
          },
        ],
      },
    })
  },
  methods: {
    getDetail(id, isInit = true, comboData = null) {
      getAction(`/hotelRoom/detail?id=${id}`).then((data) => {
        let res = data.data
        this.detail = res
        this.detail.bedType = res.bedType ? res.bedType.split(',') : []
        this.detail.roomArea = +res.roomArea
        this.detail.ticketRefundList = res.ticketRefundList || []
        this.detail.comboName = name || res.productComboName
        if (comboData) {
          this.detail.comboName = comboData.comboName
          this.detail.productComboId = comboData.id
        }
        if (isInit) {
          this.detail.comboFlag = res.comboFlag == '1' ? '0' : '1'
        }
        setTimeout(() => {
          this.$nextTick(() => {
            this.$refs.form.validate()
          })
        }, 500)

        this.$forceUpdate()
      })
    },
    storeSearch(name = '') {
      const { roomId } = this.$route.query
      getAction('/goods/farmProductCombo/getRelationList', {
        comboType: '0',
        productId: roomId,
        comboName: name,
      }).then((e) => {
        this.detail.productComboName = name
        this.comboName = e.data.map((e) => ({ name: e.comboName, value: e.id, ...e }))
      })
    },
    onMenuClick(data) {
      const { id } = this.$route.query
      this.getDetail(data.hotelRoomId, false, data)
      setTimeout(() => {
        this.detail.id = id
        this.$forceUpdate()
      }, 1000)
    },
    getForm1() {
      const { id } = this.$route.query
      return {
        title: '基本信息',
        type: 'cardForm',
        data: [
          {
            name: '是否为套餐',
            type: 'switch',
            cols: 12,
            key: 'comboFlag',
            disabled: id,
            onChange: (state) => {
              this.detail.comboFlag = state ? '1' : '0'
            },
          },
          {
            name: '排序',
            type: 'inputNumber',
            cols: 12,
            key: 'sort',
            maxLength: 35,
          },
          {
            name: '房间名称(15字符内)',
            type: 'dropDownInput',
            cols: 24,
            key: 'comboName',
            onInputSearch: this.storeSearch,
            onMenuClick: this.onMenuClick,
            valueKey: 'productComboId',
            disabled: id,
            inputProps: {
              placeholder: '搜索套餐',
              addonBefore: <a-icon type="search" />,
            },
            rules: [
              {
                required: true,
              },
            ],
            display: this.detail.comboFlag == '0',
          },
          {
            name: '房间名称(35字符内)',
            type: 'input',
            cols: 12,
            key: 'roomName',
            maxLength: 35,
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            name: '客房面积',
            type: 'inputNumber',
            cols: 12,
            key: 'roomArea',
            props: {
              min: 0,
            },
            rules: [
              {
                required: true,
                type: 'number',
              },
            ],
          },
          {
            name: '每间最多入住人数',
            type: 'inputNumber',
            cols: 12,
            key: 'maximumOccupancy',
            props: {
              min: 0,
            },
            rules: [
              {
                required: true,
                type: 'number',
              },
            ],
          },
          {
            name: '分布楼层',
            type: 'input',
            cols: 12,
            key: 'floorDistribution',
          },
          // {
          //   name: '床型面积',
          //   type: 'inputNumber',
          //   cols: 12,
          //   key: 'bedTypeArea',
          //   props: {
          //     min: 0,
          //   },
          // },
          {
            name: '房型数量',
            type: 'inputNumber',
            cols: 12,
            key: 'roomNum',
            props: {
              min: 0,
            },
          },
          {
            name: '早餐',
            type: 'select',
            cols: 12,
            key: 'breakFast',
            typeData: hotelBreakFast(),
            rules: [
              {
                required: true,
                type: 'string',
                message: '请选择早餐',
              },
            ],
          },
          {
            name: '床型(最多可选1)',
            type: 'checkBoxButton',
            cols: 12,
            key: 'bedType',
            typeData: bedTypeArr(),
            styles: {
              marginBottom: '20px',
              width: '130px',
            },
            props: {
              max: 1,
            },
            rules: [
              {
                required: true,
                type: 'array',
              },
            ],
          },
          {
            name: '窗户',
            type: 'radioButton',
            cols: 12,
            key: 'windowType',
            typeData: theWindow(),
            styles: {
              marginBottom: '20px',
            },
          },
          {
            name: '宽带情况',
            type: 'radioButton',
            cols: 12,
            key: 'broadband',
            typeData: broadbandSituation(),
          },
          {
            name: '可否加床',
            type: 'radioButton',
            cols: 12,
            key: 'addBed',
            typeData: extraBed(),
          },
          {
            name: '是否禁烟',
            type: 'radioButton',
            cols: 12,
            key: 'banSmoking',
            typeData: smoking(),
          },
          {
            name: '无线WIFI',
            type: 'radioButton',
            cols: 12,
            key: 'wifiType',
            typeData: wirelessWIFI(),
          },
        ].filter((e) => (e.display == undefined ? true : e.display)),
      }
    },
    getLabelForm(index) {
      return [
        {
          name: '标签名称',
          type: 'input',
          key: 'labelName',
        },
        {
          name: '标签类型',
          type: 'radioButton',
          key: 'publicLabel',
          typeData: [
            {
              name: '店铺私有',
              value: '2',
            },
            {
              name: '公用标签库',
              value: '1',
            },
          ],
        },
        {
          name: '标签分类',
          type: 'iconClass',
          key: 'iconUrl',
        },
      ].filter((e) => e)
    },
    getForm2() {
      return {
        type: 'cardForm',
        title: () => (
          <div>
            <span style={{ color: '#f5222d' }}>*</span>Banner（限制6张）
          </div>
        ),
        data: [
          {
            type: 'upload',
            cols: 24,
            maxLength: 6,
            key: 'bannerUrl',
            multiple: true,
            rules: [
              {
                required: true,
                message: '请上传图片',
                type: 'string',
              },
            ],
          },
        ],
      }
    },
    getForm3() {
      return {
        type: 'row',
        children: (this.labelGroup || []).map((e, i) => {
          return (
            <CardIconLabelLocal
              data={this.getLabelForm(i)}
              title={e.labelTypeName}
              pubBool={false}
              cols={i == 2 ? 24 : 12}
              style={{
                marginBottom: '10px',
                minHeight: '200px',
              }}
              icons={e.list}
            />
          )
        }),
      }
    },
    getFormTksz() {
      const that = this
      return {
        title: '退款设置',
        type: 'cardForm',
        data: [
          {
            display: true,
            name: '退款设置',
            type: 'checkBoxButton',
            key: 'freeRefundFlag',
            cols: 24,
            typeData: [
              {
                name: '在购买当天订单确认后30分钟内免费退',
                value: '1',
              },
            ],
          },
          {
            display: true,
            name: '退款方式',
            type: 'radioButton',
            key: 'refundExplain',
            cols: 6,
            typeData: [
              {
                name: '支持订单确认后，入住前可退款',
                value: '2',
              },
              {
                name: '不可退',
                value: '1',
              },
              // {
              //   name: '过期不可退',
              //   value: '2'
              // },
            ],
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            display: this.detail.refundExplain === '1',
            name: '不可退款协议',
            type: 'input',
            cols: 9,
            key: 'refundAgreement',
          },
          {
            display: that.detail.refundExplain == '2',
            cols: 24,
            type: 'table',
            showFootButton: true,
            showRowSelect: false,
            showPagination: false,
            // table新增按钮回调
            onAddData: () => {
              this.$forceUpdate()
              return {
                validDay: '0',
                validDate: '6',
                refundType: '0',
                refundExplain: 0,
              }
            },
            columns: [
              {
                dataIndex: 'validDay',
                align: 'left',
                width: '22%',
                title: (
                  <div>
                    <span style="color:#f5222d">*</span>入住有效日期
                  </div>
                ),
                customRender: function (text, records, index) {
                  const onChange = (data) => {
                    records.validDay = data
                  }
                  return (
                    <a-select onChange={onChange} style={{ width: '100%' }} placeholder="有效日期" defaultValue={text}>
                      {hotelDaysAfterList.map((e) => {
                        return <a-select-option value={e.value}>{e.name}</a-select-option>
                      })}
                    </a-select>
                  )
                },
              },
              {
                dataIndex: 'validDate',
                align: 'left',
                width: '22%',
                title: (
                  <div>
                    <span style="color:#f5222d">*</span>入住有效时间前
                  </div>
                ),
                customRender: function (text, records, index) {
                  const onChange = (data) => {
                    records.validDate = data
                  }
                  return (
                    <a-select onChange={onChange} style={{ width: '100%' }} placeholder="有效时间" defaultValue={text}>
                      {timeAfterList.map((e) => {
                        return <a-select-option value={e.value}>{e.name}</a-select-option>
                      })}
                    </a-select>
                  )
                },
              },
              {
                dataIndex: 'refundType',
                align: 'left',
                title: (
                  <div>
                    <span style="color:#f5222d">*</span>退款类别
                  </div>
                ),
                width: '22%',
                customRender: function (text, records, index) {
                  const onChange = (data) => {
                    records.refundType = data
                    records.refundExplain = 0

                    that.$forceUpdate()
                  }
                  return (
                    <a-select onChange={onChange} style={{ width: '100%' }} placeholder="退款类别" defaultValue={text}>
                      {ticketRefundType1.map((e) => {
                        return <a-select-option value={e.value}>{e.name}</a-select-option>
                      })}
                    </a-select>
                  )
                },
              },
              {
                dataIndex: 'refundExplain',
                align: 'left',
                width: '22%',
                title: (
                  <div>
                    <span style="color:#f5222d">*</span>扣款金额/系数
                  </div>
                ),
                customRender: function (text, records, index) {
                  const onInput = (data) => {
                    data = Number(data.target.value)
                    if (data < 0) {
                      data = 0
                    }

                    data = data.toFixed(1)
                    records.refundExplain = Number(data)
                    that.$forceUpdate()
                  }
                  const onChange = (data) => {
                    records.refundExplain = data

                    that.$forceUpdate()
                  }
                  if (records.refundType == '1') {
                    return (
                      <a-select
                        onChange={onChange}
                        style={{ width: '100%' }}
                        placeholder="扣款金额/系数"
                        defaultValue={text}
                      >
                        {ticketRefundRatioList.map((e) => {
                          return <a-select-option value={e.value}>{e.name}</a-select-option>
                        })}
                      </a-select>
                    )
                  }
                  return (
                    <a-input
                      disabled={records.refundType == 2}
                      type="number"
                      placeholder="扣款金额/系数"
                      onChange={onInput}
                      value={text}
                    />
                  )
                },
              },
              {
                dataIndex: 'a10',
                align: 'center',
                width: '12%',
                title: '操作',
                unShowEditBtn: true,
                tableType: 'editDelete',
                onDel: (text, records, index) => {
                  if (that.detail.ticketRefundList.length > 1) {
                    that.detail.ticketRefundList.splice(index, 1)
                  } else {
                    this.$message.error('退款规则最少保留一条规则！')
                  }
                  this.$forceUpdate()
                },
              },
            ],
            dataSource: that.detail.ticketRefundList,
          },
        ].filter((e) => e.display),
      }
    },
    getFoot() {
      const left = [
        {
          name: '上一步',
          display: this.activeKey != 0,
          onClick: () => (this.activeKey = this.activeKey - 1),
        },
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          display: true,
          sumbit: true,
          onClick: (data) => {
            // 获取标签
            let arr = []
            this.labelGroup.forEach((item) =>
              item.list.forEach((e) => {
                e['labelTypeCode'] = item.labelTypeCode
                e['labelTypeId'] = item.id
                e['labelTypeName'] = item.labelTypeName
              })
            )
            this.labelGroup.map((e) => arr.push(...e.list))
            let brr = arr.map((e) => ({
              ...e,
              czState: e.czState ? 1 : 0,
            }))

            let upDown = this.detail.id ? this.detail.upDown : '1'
            if (data.refundExplain == 2 && (this.detail?.ticketRefundList || []).length < 1) {
              this.$message.error('退款规则最少保留一条规则！')
              return
            }
            let newTicketRefundList = (this.detail?.ticketRefundList || []).map((e) => {
              return {
                ...e,
                validDayName: hotelDaysAfterList.find(({ value }) => value === e.validDay).name,
                validDateName: timeAfterList.find(({ value }) => value === e.validDate).name,
                refundExplain: e.refundExplain ? e.refundExplain : 0,
              }
            })
            if (this.detail.comboFlag == '0') {
              if (!this.detail.productComboId) {
                message.error('请选择套餐对应的房间名称')
                return
              }
            }
            const { roomId, hotelId } = this.$route.query
            let params = {
              ...data,
              banSmokingName: smoking().find((f) => f.value == data.banSmoking)?.name || '',
              bedTypeName: bedTypeArr()
                .filter((e) => data.bedType?.includes(e.value))
                .map((e) => e.name)
                .toString(),
              broadbandName: broadbandSituation().find((f) => f.value == data.broadband)?.name || '',
              wifiTypeName: wirelessWIFI().find((f) => f.value == data.wifiType)?.name || '',
              windowTypeName: theWindow().find((f) => f.value == data.windowType)?.name || '',
              addBedName: extraBed().find((f) => f.value == data.addBed)?.name || '',
              bedType: data.bedType ? data.bedType.toString() : '',
              hotelId: this.hotelId,
              upDown: upDown,
              farmLabelDataDTOS: brr,
              freeRefundFlag: data?.freeRefundFlag?.toString() || '',
              ticketRefundList: newTicketRefundList,
              productComboName: this.detail.productComboName,
              productComboId: this.detail.productComboId,
              productId: roomId,
              roomId,
              comboFlag: this.detail.comboFlag == '1' ? '0' : '1',
            }

            api.command[this.detail.id ? 'edit' : 'create']
              .call(this, {
                url: `/hotelRoom/${this.detail.id ? 'update' : 'save'}`,
                params,
                isPost: false,
              })
              .then((result) => {
                const { roomId, hotelId } = this.$route.query
                this.$router.push(`/commodityManagement/roomManage?hotelId=${hotelId}&roomId=${roomId}`)
              })
          },
        },
        {
          name: '取消',
          display: true,
          type: 'default',
          onClick: () => {
            const { roomId, hotelId } = this.$route.query
            this.$router.push(`/commodityManagement/roomManage?hotelId=${hotelId}&roomId=${roomId}`)
          },
        },
        {
          name: '下一步',
          display: this.activeKey != 1,
          onClick: (data) => {
            if (
              !(
                this.detail.roomName &&
                (this.detail.roomArea === 0 || this.detail.roomArea) &&
                (this.detail.maximumOccupancy === 0 || this.detail.maximumOccupancy) &&
                this.detail.breakFast &&
                this.detail.bedType &&
                this.detail.bedType.length &&
                this.detail.bannerUrl
              )
            ) {
              this.activeKey = 0
              this.$message.warning('请完成表单填写')
              return
            }
            this.activeKey = +this.activeKey + 1
          },
        },
      ].filter((e) => e.display)
      const right = [
        {
          name: ` ${this.detail.upDown == 0 ? '下架' : '上架'} `,
          type: 'default',
          onClick: () => {
            putAction(`/hotelRoom/${this.detail.upDown == 0 ? 'downBatch' : 'upBatch'}`, {
              idList: [this.detail.id],
            }).then((e) => {
              if (e.code == 200) {
                const { roomId, hotelId } = this.$route.query
                this.$message.success(`${this.detail.upDown == 0 ? '下架成功' : '上架成功'}`)
                setTimeout(() => {
                  this.$router.push(`/commodityManagement/roomManage?hotelId=${hotelId}&roomId=${roomId}`)
                }, 500)
              } else {
                this.$message.error(e.msg)
              }
            })
          },
        },
        {
          name: '删除',
          popTitle: '确认删除吗?',
          isPop: true,
          type: 'danger',
          ghost: true,
          onClick: () => {
            api.command.delPut.call(this, {
              url: `/hotelRoom/deleteBatch`,
              params: { idList: [this.detail.id] },
              isPost: false,
            })
            setTimeout(() => {
              const { roomId, hotelId } = this.$route.query
              this.$router.push(`/commodityManagement/roomManage?hotelId=${hotelId}&roomId=${roomId}`)
            }, 500)
          },
        },
      ]
      return {
        left,
        right: this.detail.id ? right : [],
      }
    },
    getFormTk() {
      return {
        title: '订单退款确认方式',
        type: 'cardForm',
        data: [
          {
            display: true,
            name: '退款确认方式',
            type: 'radioButton',
            key: 'refundType',
            cols: 12,
            typeData: [
              {
                name: '人工审核退款',
                value: '0',
              },
              {
                name: '自动处理退款',
                value: '1',
              },
            ],
            rules: [
              {
                required: true,
              },
            ],
          },
        ].filter((e) => e.display),
      }
    },

    renderTab() {
      if (this.activeKey === 0) {
        return [this.getForm1(), this.getForm2(), this.getForm3()]
      } else if (this.activeKey === 1) {
        if (this.detail.comboFlag == 0) {
          return [this.getFormTksz()]
        } else {
          return [this.getFormTksz(), this.getFormTk()]
        }
      }
    },
  },
  render() {
    return (
      <div>
        <Naves
          navData={['基础设置', '退款设置']}
          onActive={(index) => {
            if (
              !(
                this.detail.roomName &&
                (this.detail.roomArea === 0 || this.detail.roomArea) &&
                (this.detail.maximumOccupancy === 0 || this.detail.maximumOccupancy) &&
                this.detail.breakFast &&
                this.detail.bedType &&
                this.detail.bedType.length &&
                this.detail.bannerUrl
              )
            ) {
              this.activeKey = 0
              this.$message.warning('请完成表单填写')
              return
            } else {
              this.activeKey = index
            }
          }}
          activeCurrentKey={this.activeKey}
        />
        <DetailFormGroup
          ref="form"
          foot={this.getFoot()}
          form={this.detail}
          data={this.renderTab()}
          typeData={{ comboName: this.comboName }}
        />
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

/deep/.ant-input[type='number'] {
  -moz-appearance: textfield;
}
/deep/.ant-input[type='number']::-webkit-inner-spin-button,
/deep/.ant-input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
